$small: 450px;
$medium: 900px;
.home {
	height: 100vh;
	height: var(--app-height);
	&__slider-item {
		height: 100vh;
		height: var(--app-height);
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
		display: grid;
		text-align: center;
	}
	&__slider-overlay {
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		justify-items: center;
		width: 100%;
		background: rgba(0, 0, 0, 0.6);
		padding: 2.8rem 1rem;
		position: relative;
		z-index: 1;
		align-self: center;
	}
	&__slider-overlay-content {
		display: grid;
		justify-items: center;
		margin: 0 2.3rem;
	}
	&__slider-tour {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	&__slider-client,
	&__slider-artist {
		margin: 0rem;
	}
	&__slider-tour {
		font-size: 36px;
		line-height: 43px;
	}
	&__slider-client,
	&__slider-artist {
		font-size: 18px;
		line-height: 22px;
	}
	/* Style the video: 100% width and height to cover the entire window */
	&__slider-link {
		position: fixed;
		min-width: 100vw;
		z-index: 0;
		div{
			width: 100vw;
			height: 100vh;
			height: var(--app-height);
		}
	}
	&__slider-blank {
		width: 100vw;
		height: 100vh;
		height: var(--app-height);
	}
	&__slider-video {
		position: relative;
		overflow: hidden;
		z-index:0;
		video{
			object-fit: cover;
			width: 100vw;
			height: 100vh;
			height: var(--app-height);
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 0;
		}
	}
	&__slider-img {
		background-size: cover;
		background-position: center;
	}
	@media only screen and (max-width: $small) {
		&__slider-client,
		&__slider-artist {
			font-size: 14px;
			line-height: 17px;
		}
		&__slider-tour {
			font-size: 24px;
			line-height: 29px;
		}
	}
}
