@import url(./colors.scss);
@import url(./fonts.scss);
@import url(./home.scss);
@import url(./projects.scss);
@import url(./projectSingle.scss);
$small: 450px;
$medium: 900px;
* {
	box-sizing: border-box;
}
html,
body {
	font-family: "Gotham", Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: var(--white);
	background-color: var(--black);
}
a {
	color: inherit;
	text-decoration: none;
}
.App {
	height: 100vh;
	height: var(--app-height);
}
.slick-prev::before,
.slick-next::before {
	content: none !important;
}
.slick-arrow {
	z-index: 2;
	width: 12px !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
}
.slick-prev {
	// left: 20% !important;
}
.slick-next {
	// right: 20% !important;
	// transform: translate(0, -50%) rotate(180deg) !important;
}
.display-left-arrow {
	position: relative !important;
	left: 0 !important;
	right: 0 !important;
	background-image: url("../assets/arrow.svg") !important;
	justify-self: end;
}
.display-right-arrow {
	position: relative !important;
	left: 0 !important;
	right: 0 !important;
	background-image: url("../assets/arrow.svg") !important;
	transform: translate(0, -50%) rotate(180deg) !important;
	justify-self: start;
}
.site-nav {
	background-color: transparent;
	color: var(--white);
	display: grid;
	grid-template-columns: auto auto;
	width: 100vw;
	position: fixed;
	bottom: 0;
	padding: 1rem 1.5rem;
	z-index: 3;
	&__list {
		justify-self: start;
		align-self: center;
	}

	&__communication {
		justify-self: end;
		align-self: center;
	}
	&__list,
	&__communication {
		display: flex;
		flex-flow: row nowrap;
		font-weight: 900;
		padding: 0px;
	}
	&__list-item,
	&__communication-item {
		list-style-type: none;
		margin-right: 1rem;
	}
	&__communication-item:last-child {
		margin-right: 0rem;
	}
	&__active {
		display: none;
	}
	@media only screen and (max-width: $medium) {
		padding: 1rem;
	}
}
