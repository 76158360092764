$small: 450px;
$medium: 900px;
.projects {
	height: 100vh;
	height: var(--app-height);
	background-size: cover;
	background-repeat: no-repeat;
	&__wrapper {
		background-color: rgba(0, 0, 0, 0.6);
		height: 100%;
		display: grid;
		grid-template-rows: 100%;
	}
	&__filter {
		font-size: 14px;
		font-weight: 900;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: center;
		justify-content: center;
		padding: 2.8em 0em;
		white-space: nowrap;
		overflow-x: scroll;
		-ms-overflow-style: none;
		scrollbar-width: none;
		position: fixed;
		width: 100vw;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	&__filter-item {
		list-style-type: none;
		margin-right: 2.8em;
		cursor: pointer;
		display: inline;
	}
	&__filter-item:last-child {
		margin-right: 0em;
	}
	&__list {
		font-size: 24px;
		font-weight: 900;
		line-height: 29px;
		letter-spacing: 0em;
		display: grid;
		justify-content: start;
		align-items: center;
		padding: 0em 3.3em;
		align-self: center;
		max-height: calc(var(--app-height) - 220px);
		overflow-y: scroll;
	}
	&__list-item {
		justify-self: start;
		list-style-type: none;
		margin-bottom: 0.8em;
		padding-bottom: 0.2em;
		cursor: pointer;
		position: relative;
		display: inline-block;
	}
	&__list-item:before {
		content: "";
		position: absolute;
		width: 0;
		height: 2px;
		bottom: 0;
		left: 0;
		background-color: #FFF;
		visibility: hidden;
		transition: all 0.3s ease-in-out;
	}
	&__list-item:hover:before {
		visibility: visible;
		width: 100%;
	}
	@media only screen and (max-width: $medium) {
		&__list {
			font-size: 18px;
			padding: 0em 1em;
			max-height: calc(var(--app-height) - 162px);
		}
		&__filter {
			font-size: 12px;
			padding: 1.4em 2.8em;
		}
	}
}
