$small: 450px;
$medium: 900px;
.project-single {
	position: relative;
	min-height: 100vh;
	height: var(--app-height);
	&__header {
		position: fixed;
		padding: 2.8rem;
		overflow-x: hidden;
		z-index: 4;
		width: 100%;
	}
	&__gallery {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	&__gallery-image {
		width: 100%;
	}
	&__header-text-1{
		display: grid;
		grid-template-columns: 1fr auto;
		align-items: end;
		h3{
			margin-bottom: 0;
		}
	}
	&__header-text-1,
	&__header-text-3 {
		font-size: 18px;
		font-weight: 900;
		line-height: 22px;
		letter-spacing: 0em;
	}
	&__header-text-2 {
		font-size: 36px;
		font-weight: 900;
		line-height: 43.2px;
		letter-spacing: 0em;
	}
	&__credits-button {
		font-size: 14px;
		font-weight: 900;
		line-height: 17px;
		letter-spacing: 0em;
		cursor: pointer;
		display: inline-block;
	}
	&__credits-text {
		font-size: 14px;
		font-weight: 700;
		line-height: 17px;
		letter-spacing: 0em;
		margin-top: 7.1em;
		text-transform: none;
	}
	&__credits-text-close {
		width: 17px;
		height: 17px;
		background-image: url("../assets/x.svg");
		background-color: transparent;
		background-size: contain;
		background-repeat: no-repeat;
		border: none;
		display: none;
	}
	@media only screen and (max-width: $small) {
		&__header {
			padding: 1.4rem;
		}
		&__header-text-1,
		&__header-text-3 {
			font-size: 14px;
			line-height: 17px;
		}
		&__header-text-2 {
			font-size: 24px;
			font-weight: 900;
			line-height: 30px;
			letter-spacing: 0em;
		}
		&__credits-text {
			margin-top: 2.8em;
		}
	}
}
