@font-face {
	font-family: "Gotham";
	src: url(../fonts/Gotham-Light.otf);
	font-weight: 400;
}
@font-face {
	font-family: "Gotham";
	src: url(../fonts/Gotham-Bold.otf);
	font-weight: 600;
}
@font-face {
	font-family: "Gotham";
	src: url(../fonts/Gotham-Black.otf);
	font-weight: 900;
}
